/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Alice&family=Marck+Script&family=Playfair+Display:ital,wght@0,400;0,500;0,700;1,400;1,700&family=Sacramento&family=Slabo+27px&family=Source+Serif+4:ital,opsz,wght@0,8..60,300;0,8..60,400;1,8..60,200&family=Style+Script&family=Zeyada&display=swap');

/* 
Alice
(Regular 400) 

Sacramento
(Regular 400) 

Zeyada
(Regular 400) 

Playfair Display 
(Regular 400) 
Regular 400 Italic
Medium 500
Bold 700
Bold 700 Italic

Source Serif 4
(Regular 400)
ExtraLight 200 Italic
Light 300

Slabo 27px
(Regular 400)

Marck Script
(Regular 400)

Style Script 
(Regular 400) 

*/

:root {
--slate-grape: #7E6976;
--pastel-grape: #f4ece4;
--blush: rgb(186, 104, 115);
--light-rose: #f8d6d6;
--champange: rgb(255, 251, 248);
--dusty-rose: #B76E79;
--navy: rgb(54, 60, 78);
--light-red: #991423;
--red: #7F1A2B;
--dark-red: rgb(100, 31, 50);
--white: rgb(255, 255, 240);
--gold: rgb(175, 140, 90);
--navy-gradient: linear-gradient(160deg, rgb(54, 60, 78, 0.7 ),  rgb(54, 60, 78, 0.9 ), rgb(54, 60, 78, 0.9 ), rgb(54, 60, 78, 0.9 ));
--body-gradient: linear-gradient(150deg, rgb(240, 209, 225), rgb(123, 40, 84));
}
 


h1 {
  color: var(--champange)  !important;
  font-size: 190px !important;
  font-family: 'Style Script';
  font-style: none;
  letter-spacing: 1px;
  margin-bottom: -60px !important;
}

h2 {
  font-family: 'Style Script';
  letter-spacing: 0.5px;
  color: var(--champange) !important;
  font-size: 40px !important;
}

h3 {
  color: var(--champange) !important;
}

li {
  color: white;
  font-size: 30px;
  list-style-type: none;
}

.i {
  font-style: italic;
  font-size: 20px;
}

p {
  color: var(--champange);
  font-family: "Playfair Display";
  font-size: 16px;
  font-weight: bold;
  margin-bottom: .25rem!important;
}

.firebaseui-id-page-sign-in {
  background-color: var(--champange) !important;
  color:rgb(167, 11, 94)   !important;
}

.firebaseui-id-submit {
  background-color: rgb(150, 58, 107)  !important;
}

.firebaseui-id-submit:hover {
  background-color: rgb(167, 11, 94)  !important;
}

.firebaseui-label, #ui-sign-in-email-input {
  color: rgb(150, 58, 107) !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: rgb(40, 44, 52);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#link {
  /* margin-right: 20px; */
  font-size: 20px;
}

#link-bottom {
  /* color: var(--champange); */
  margin-right: 25px;
  font-size: 35px;
  color: var(--light-rose);

}

#link-bottom:hover {
  color: rgb(239, 76, 163);
}

a:link, a:visited, a:active, a:hover, button {
  text-decoration: none;
  color: var(--blush);
  border-bottom: none;
  transition: color 0.3s ease, border 1s ease-in-out;
}

button {
  padding: 0;
  margin: 0;
  /* background-color: #7F1A2B !important; */
}

a:hover, button:hover {
  color: var(--light-red);
}

li > a {
  display: flex;
  justify-content: center;
}

button {
  background-color: transparent;
  border: none;
}

.hamburger-menu {
  background: linear-gradient(120deg, rgb(210, 104, 161, 0.5), rgb(150, 58, 107));
  border-radius: 5px;
  padding: 0px 10px;
  font-size: 30px;
  color: var(--blush);
  display: none; /* hide by default */
}

/* web only */
.navigation-component {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .hamburger-menu {
    display: block; /* show on mobile */
    margin-right: 5px;
  }
  
  .navigation-component {
    display: none; /* hide on mobile */
  }
}

#main {
  background: var(--grape-gradient);
  z-index: 0;
  /* background: var(--body-gradient); */
}

#side-nav {
  font-size: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}

#link:active, #link:link, #link:visited {
  text-decoration: none;
}

.footer {
  background-color: rgb(150, 58, 107);
  color: var(--blush);
  padding: 10px 0;
  text-align: center;
  /* bottom: 0px;
  position: fixed; */
  width: 100%;
  overflow-x: hidden;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.new-footer {
  background: var(--navy);
  color: var(--champange);
  padding: 10px 0;
  text-align: center;
  bottom: 0px;
  position: fixed;
  width: 100%;
  overflow-x: hidden;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}

#header {
  padding: 20px 0px;
  background-color: var(--navy) ;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Use viewport height as minimum height */
}

.content {
  flex: 1; /* Allow content to take up all available space */
}

@media screen and (max-width: 700px) {
  h1 {
    font-size: 120px !important;
    margin-bottom: -30px !important;
  }
}